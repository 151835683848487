import {Box} from "@material-ui/core"
import {styled} from "@material-ui/core/styles"
import {observer} from "mobx-react"
import moment from "moment"
import * as queryString from "querystring"
import * as React from "react"
import {lazy} from "react"
import {Route, RouteComponentProps, Switch} from "react-router-dom"
import {HEADER_HEIGHT} from "../../../../components/Header/Header"
import {theme} from "../../../../styles/MuiThemes"
import {log} from "../../../../util/Logging"
import {screenUtil} from "../../../../util/ScreenUtil"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {productStore} from "../../../common/products/ProductStore"
import {ClassPrintAll, ClassPrintList, ClassPrintSummary} from "../../class/components/ClassesReportPrintView"
import {PromptsPrintAll, PromptsPrintSelected} from "../../class/components/PromptsPrintView"
import {
    DistrictReportPage,
    SchoolReportPage
} from "../../district-school/components/DistrictOrSchoolReportPage"
import {
    DistrictPrintAll,
    DistrictPrintLocations,
    DistrictPrintSummary
} from "../../district-school/components/DistrictReportPrintView"
import {
    SchoolPrintAll,
    SchoolPrintLocations,
    SchoolPrintSummary
} from "../../district-school/components/SchoolReportPrintView"
import {IndividualPrintAll, IndividualPrintSelected} from "../../individual/components/IndividualReportPrintView"
import {ReportSubPaths} from "../../ReportSubPaths"
import {ReportUtils} from "../../ReportUtils"
import {ReportFilters} from "../models/ReportFilters"
import {RedirectToReport} from "./FindReportIdOrRedirect"
import {authStore} from "../../../common/authentication/AuthStore"
import {PLACE_PRODUCT_ID, STAMP_CEFR_PRODUCT_ID, STAMP_SIGN_LANGUAGE_PRODUCT_ID} from "../../../../util/Constants"
import {PLACEReportPage} from "../../PlaceFamily/PLACEReportPage"
import {PLACEIndividualReportPage} from "../../PlaceFamily/PLACEIndividualReportPage"
import {extractPrintOption} from "../../../newReports/printing/PrintViewContainer"
import {PrintOptions} from "./PrintViewContainer"
//These imports will be lazing loaded in to seperate chunks make the main and first chuck loads smaller.
const ClassReportPage = lazy(() => import('../../class/components/ClassReportPage'))
const IndividualReportsPage = lazy(() => import('../../individual/components/IndividualReportsPage'))
const NewReportsContainer = lazy(() => import("../../../newReports/Report"))
const NewIndividualReportsContainer = lazy(() => import("../../../newReports/individualReport/IndividualReport"))
const NewReportPrintViewContainer = lazy(() => import("../../../newReports/printing/PrintViewContainer"))
const NewIndividualPrintViewContainer = lazy(() => import("../../../newReports/printing/IndividualPrintView"))

export const reportsPadding = theme.spacing(4)
export const REPORTS_MAX_WIDTH = 1440

interface ReportsPageProps {
    groupId: number | null
}
@observer
export default class ReportsPage extends React.Component<RouteComponentProps & ReportsPageProps> {

    componentDidMount(): void {
        log.debug("Find login group hierarchy in reports page")
        if (authStore.isSsoAvantAuthorized && this.props.groupId !== null) {
            if (this.props.groupId === undefined) {
                const groupIdString = sessionStorage.getItem("groupId")
                if (groupIdString !== null && groupIdString !== undefined) {
                    if (authStore.auth?.ssoType === "SalesForce" && productStore.loginProduct?.productId !== null && productStore.loginProduct?.productId !== undefined) {
                        groupMasterStore.findDefaultSAMLGroup(productStore.loginProduct.productId)
                    }

                }

            } else {
                groupMasterStore.selectGroup(this.props.groupId)
            }
        } else {
            groupMasterStore.findDefaultGroup()
        }
    }

    componentWillUnmount(): void {
        groupMasterStore.defaultGroup = undefined
    }

    renderDistrictReport(productId: number) {
        switch(productId) {
            case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
                return NewReportsContainer
            default:
                return DistrictReportPage
        }
    }

    renderSchoolReport(productId: number) {
        switch(productId) {
            case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
                return NewReportsContainer
            default:
                return SchoolReportPage
        }
    }

    renderClassReport(productId: number) {
        switch (productId) {
            case PLACE_PRODUCT_ID.value():
                return PLACEReportPage
            case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
            case STAMP_CEFR_PRODUCT_ID.value():
                return NewReportsContainer
            default:
                return ClassReportPage
        }
    }

    renderIndividualReport(productId: number) {
        switch (productId) {
            case PLACE_PRODUCT_ID.value():
                return PLACEIndividualReportPage
            case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
            case STAMP_CEFR_PRODUCT_ID.value():
                return NewIndividualReportsContainer
            default:
                return IndividualReportsPage
        }
    }

    // Temporary methods to handle ASL
    // TODO: Do this for District/School
    renderClassPrintViews() {
        const productId = productStore.loginProduct!!.productId
        if (productId !== STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
            const printOption = extractPrintOption()!
            if (printOption === PrintOptions.ALL) {
                return ClassPrintAll
            }
            if (printOption === PrintOptions.SUMMARY) {
                return ClassPrintSummary
            }
            if (printOption === PrintOptions.LIST) {
                return ClassPrintList
            }
        }

        return NewReportPrintViewContainer
    }

    renderIndividualPrintViews() {
        const productId = productStore.loginProduct!!.productId
        if (productId !== STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
            const printOption = extractPrintOption()!
            if (printOption === PrintOptions.ALL) {
                return IndividualPrintAll
            }

            return IndividualPrintSelected
        }

        return NewIndividualPrintViewContainer
    }

    render() {
        if ((groupMasterStore.defaultGroup === null || groupMasterStore.defaultGroup === undefined) || productStore.driver === null || productStore.loginProduct === undefined) {
            log.debug("Render loading group hierarchy or driver null")
            return <AvantCircularProgress padding={12}/>
        }

        const printing = ReportUtils.isPrinting(this.props.location.pathname)

        return (
            <ReportWrapper
                style={{
                    padding: reportsPadding,
                    paddingTop: printing ? 0 : HEADER_HEIGHT + theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: screenUtil.screenWidth > REPORTS_MAX_WIDTH + reportsPadding * 2 ? "center" : undefined,
                    marginTop: "50px" // This is needed for the ClassicReportsBanner, make sure to remove this line when we remove the banner.
                }}
            >
                <div style={{maxWidth: REPORTS_MAX_WIDTH}}>
                    <Switch>
                        <Route path={ReportSubPaths.districtPrintAll()} component={DistrictPrintAll} exact={true}/>
                        <Route path={ReportSubPaths.districtPrintSummary()} component={DistrictPrintSummary} exact={true}/>
                        <Route path={ReportSubPaths.districtPrintLocations()} component={DistrictPrintLocations} exact={true}/>
                        <Route path={ReportSubPaths.district()} component={this.renderDistrictReport(productStore.loginProduct.productId)} exact={true}/>

                        <Route path={ReportSubPaths.schoolPrintAll()} component={SchoolPrintAll} exact={true}/>
                        <Route path={ReportSubPaths.schoolPrintSummary()} component={SchoolPrintSummary} exact={true}/>
                        <Route path={ReportSubPaths.schoolPrintList()} component={SchoolPrintLocations} exact={true}/>
                        <Route path={ReportSubPaths.school()} component={this.renderSchoolReport(productStore.loginProduct.productId)} exact={true}/>

                        <Route path={ReportSubPaths.classPrintSummary()} component={this.renderClassPrintViews()} exact={true}/>
                        <Route path={ReportSubPaths.classPrintList()} component={this.renderClassPrintViews()} exact={true}/>
                        <Route path={ReportSubPaths.classPrintAll()} component={this.renderClassPrintViews()} exact={true}/>
                        <Route path={ReportSubPaths.class()} component={this.renderClassReport(productStore.loginProduct.productId)} exact={true}/>

                        <Route path={ReportSubPaths.promptsPrintAll()} component={PromptsPrintAll} exact={true}/>
                        <Route path={ReportSubPaths.promptsPrintSelected()} component={PromptsPrintSelected} exact={true}/>

                        <Route path={ReportSubPaths.individualPrintAll()} component={this.renderIndividualPrintViews()} exact={true}/>
                        <Route path={ReportSubPaths.individualPrintSelected()} component={this.renderIndividualPrintViews()} exact={true}/>
                        <Route path={ReportSubPaths.individual()} component={this.renderIndividualReport(productStore.loginProduct.productId)} exact={true}/>

                        <Route path={ReportSubPaths.REPORTS_REDIRECT} component={RedirectToReport}/>
                    </Switch>
                </div>
            </ReportWrapper>
        )
    }
}


const ReportWrapper = styled(Box)({
    "@media print": {
        "tr > * ": {
            padding: "0px 3px !important",
        },
        "& button": {
            display: "none",
        },
        "a[href]::after": {
            content: "none !important",
        },
    }
})

export interface ReportRouteProps extends RouteComponentProps<{ productId: string, groupId?: string, takeId?: string, reportType?: string }> {}

export const findFilters = (props: ReportRouteProps) => {
    const groupId = Number(props.match.params.groupId)
    const productId = Number(props.match.params.productId)
    const query: { startDate?: string, endDate?: string, assessmentStart?: string, recentActivity?: string } =
        queryString.parse(props.location.search.replace("?", ""))
    const {startDate, endDate, assessmentStart, recentActivity} = query

    const filters: ReportFilters = {
        groupId,
        productId,
        startDate: moment().subtract(6, "months"),
        endDate: moment(),
        assessmentStart: assessmentStart != null && assessmentStart !== "false",
        recentActivity: recentActivity != null && recentActivity !== "false"
    }
    if (startDate != null) {
        filters.startDate = moment(startDate)
        filters.endDate = moment(endDate)
    }
    if (!assessmentStart && !recentActivity) {
        filters.recentActivity = true
    }
    return filters
}
