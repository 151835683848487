import {Divider, Grid} from "@material-ui/core"
import {observer} from "mobx-react"
import * as React from "react"
import {H1Text, H5Text} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {log} from "../../../../util/Logging"
import {MULTIPLE_LANGUAGES} from "../../../../util/Constants"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {GroupInfo} from "../../../common/group/models/GroupInfo"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {AvantExpansionPanel} from "../../../generic-components/AvantExpansionPanel"
import {DistributionChart} from "../../common/components/aggregate-charts/DistributionChart"
import {SkillAverageChart} from "../../common/components/aggregate-charts/SkillAverageChart"
import {BreadcrumbHeader} from "../../common/components/Breadcrumbs"
import {FilterAndPrintOptions} from "../../common/components/FilterAndPrintOptions"
import {LevelKeyContent} from "../../common/components/level-key/LevelKeyContent"
import {PrintOptions} from "../../common/components/PrintViewContainer"
import {DateRangeHeaderText} from "../../common/components/report-page/DateRangeHeaderText"
import {GraphHelper} from "../../common/components/report-page/GraphHelper"
import {findFilters, ReportRouteProps} from "../../common/components/ReportsPage"
import {BlueReportType} from "../../common/models/BlueReportType"
import {GenericReportContainerProps} from "../../common/models/GenericReportProps"
import {ReportFilters} from "../../common/models/ReportFilters"
import {ReportGraphFilters} from "../../common/models/ReportGraphFilters"
import {reportsStore} from "../../common/stores/ReportStore"
import {LanguageBar} from "../../district-school/components/DistrictOrSchoolReportPage"
import {ReportSummaryGraphs} from "../../district-school/models/ReportSummaryGraphs"
import {TableDataResults} from "../models/TableDataResults"
import moment from "moment"
import {TestControls} from "./TestControls"
import {TestTakerSortableTable} from "./TestTakerSortableTable"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import {authStore} from "../../../common/authentication/AuthStore";
import LanguageUtils from "../../../../util/LanguageUtils"

export default class ClassReportPage extends React.Component<ReportRouteProps> {
    render() {
        const filters = findFilters(this.props)
        return <ClassReportContainer filters={filters}/>
    }
}

@observer
class ClassReportContainer extends React.Component<GenericReportContainerProps> {

    constructor(props: GenericReportContainerProps) {
        super(props)

        // Make sure the first render cycle doesn't have data from the previously loaded report.
        reportsStore.reportTableData = undefined
        reportsStore.reportGraphData = undefined
    }

    componentDidMount(): void {
        log.debug("component did mount of class report container, class group id " + this.props.filters.groupId)
        this.searchReportData(this.props)
        document.title = `Avant | Class Report`
    }

    // REMOVED componentWillReceiveProps

    componentDidUpdate(prevProps: Readonly<GenericReportContainerProps>) {
        const {filters} = this.props
        if (
            filters.groupId !== prevProps.filters.groupId
            || (!filters.startDate.isSame(prevProps.filters.startDate))
            || (!filters.endDate.isSame(prevProps.filters.endDate))
            || filters.assessmentStart !== prevProps.filters.assessmentStart
            || filters.recentActivity !== prevProps.filters.recentActivity
        ) {
            this.searchReportData(this.props)
        }
    }

    getQuery = (props: GenericReportContainerProps): ReportGraphFilters => {
        const {filters} = props
        const {groupId, productId, startDate, endDate, assessmentStart, recentActivity} = filters

        // make copies so we don't change state
        const startDateStartOfDay = moment(startDate).startOf("day").valueOf()
        const endDateEndOfDay = moment(endDate).endOf("day").valueOf()

        reportsStore.searchFilters = {groupId, productId, assessmentStart, recentActivity, startDate, endDate}

        return {
            groupId,
            productId,
            start: startDateStartOfDay,
            end: endDateEndOfDay,
            searchByStartTimes: assessmentStart,
            searchByMostRecentActivityTimes: recentActivity,
            loadAverages: true,
            omitEmptySkillsNotInAssessment: true
        }

    }


    searchReportData = (props: GenericReportContainerProps) => {
        const query = this.getQuery(props)
        reportsStore.findReportGraphs(query)
        reportsStore.findReportTableData(query)
        reportsStore.resetDownloads()
        groupMasterStore.groupInfo = undefined
        groupMasterStore.findGroupInfo(props.filters.groupId)
    }

    render() {
        if (typeof(reportsStore.reportGraphData) === "undefined" || typeof(groupMasterStore.groupInfo) === "undefined") {
            return <AvantCircularProgress/>
        }
        return (
            <ClassReportView
                graphData={reportsStore.reportGraphData!}
                groupInfo={groupMasterStore.groupInfo}
                filters={this.props.filters}
                query={this.getQuery(this.props)}
                tableData={reportsStore.reportTableData}
            />
        )
    }
}

export interface ClassReportViewProps {
    graphData: ReportSummaryGraphs
    groupInfo?: GroupInfo
    filters: ReportFilters
    query?: ReportGraphFilters
    printOptions?: PrintOptions
    tableData?: TableDataResults
}

export class ClassReportView extends React.Component<ClassReportViewProps> {

    render() {
        const {groupInfo, query, filters, printOptions, graphData, tableData} = this.props
        const {startDate, endDate} = filters
        const isMultiLanguage = groupInfo && groupInfo.language === MULTIPLE_LANGUAGES
        let language = LanguageUtils.removeTilde(groupInfo!!.language)

        return (
            <>
                <div style={{marginBottom: theme.spacing(4), width: "100%"}}>
                    {printOptions == null && <BreadcrumbHeader groupInfo={groupInfo} filters={filters}/>}
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={6}>
                            {groupInfo && (
                                <>
                                    <H5Text style={{marginBottom: 4}}>
                                        {language}
                                    </H5Text>
                                    <H1Text>
                                        {groupInfo.name}
                                    </H1Text>
                                </>
                            )}
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Grid container={true} alignContent={"flex-end"} spacing={1}>

                                {printOptions != null ? (
                                    <LevelKeyContent/>) : (
                                    <Grid item={true} xs={12}>
                                        <FilterAndPrintOptions
                                            reportType={BlueReportType.CLASS}
                                            filters={filters}
                                            query={query!!}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {printOptions !== PrintOptions.LIST && (
                    <AvantExpansionPanel
                        style={{breakAfter: "page"}}
                        expanded={printOptions != null ? true : undefined}
                        title={"Summary"}
                    >
                        <AccordionDetails
                            style={{
                                width: "100%",
                                display: "inherit",
                                padding: printOptions != null ? 4 : undefined
                            }}
                        >
                            {
                                !isMultiLanguage &&
                                <>
                                    {/*Skill Average Charts*/}
                                    <DateRangeHeaderText
                                        header={"Testing Group Averages"}
                                        from={startDate}
                                        to={endDate}
                                    />
                                    <SkillAverageChart
                                        averages={graphData.testingGroupAverages!}
                                    />
                                </>
                            }
                            <div style={{display: "flex", alignItems: "center"}}>
                                <DateRangeHeaderText
                                    header={"Distribution of Test Takers Across Levels"}
                                    from={startDate}
                                    to={endDate}
                                />
                                <div style={{flexGrow: 1}}/>
                                <GraphHelper/>
                            </div>
                            {graphData.graphs.length < 1 && (
                                <div>
                                    No data to display.  Summary graphs will appear when a test taker has completed a
                                    test section and is displayed in the Test Instances List.
                                </div>
                            )}
                            {graphData.graphs.map(languageSummaryData => {
                                return (
                                    <div
                                        className={"distribution-graphs"}
                                        key={languageSummaryData.language}
                                        style={{breakInside: "avoid"}}
                                    >
                                        {
                                            isMultiLanguage &&
                                            <LanguageBar language={languageSummaryData.language}/>
                                        }
                                        <Grid
                                            container={true}
                                            justifyContent={"center"}
                                            style={{width: "100%", marginTop: theme.spacing(1)}}
                                        >
                                            {languageSummaryData.summarySkillGraph.map(skillGraph => {
                                                return <DistributionChart
                                                    skillData={skillGraph}
                                                    productId={filters.productId}
                                                    key={skillGraph.skillName}
                                                />
                                            })}
                                        </Grid>
                                    </div>
                                )
                            })}
                        </AccordionDetails>
                    </AvantExpansionPanel>
                )}
                {printOptions == null &&
                    <Grid item={true} xs={12}>
                        <TestControls groupInfo={groupInfo} reportQuery={query}/>
                    </Grid>
                }
                {printOptions !== PrintOptions.SUMMARY && (
                    <AvantExpansionPanel
                        title={"List"}
                        expanded={printOptions != null ? true : undefined}
                    >
                        <div>
                            <DateRangeHeaderText
                                header={"Test Takers"}
                                from={startDate}
                                to={endDate}
                                style={{margin: theme.spacing(3)}}
                            />
                            <Divider/>
                            {tableData == null ? (
                                <AvantCircularProgress/>
                            ) : (
                                <TestTakerSortableTable
                                    filters={filters}
                                    printing={printOptions != null}
                                    data={tableData}
                                    isMultiLanguage={isMultiLanguage}
                                    groupInfo={groupInfo}
                                    isTestTakerEditingAllowed={authStore.isTestTakerEditingAllowed()}
                                />
                            )}
                        </div>
                    </AvantExpansionPanel>
                )}
            </>
        )
    }
}

