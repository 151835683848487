/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {GroupReportRow} from "../../reports/district-school/models/GroupReportRow"
import React, {useEffect, useState} from "react"
import MaterialTable, {Column} from "material-table"
import {ReportProps} from "../ReportData"
import {AvantExpansionPanel} from "../../generic-components/AvantExpansionPanel"
import {TimeUtil} from "../../../util/TimeUtil"
import {reportsStore} from "../../reports/common/stores/ReportStore"
import {AvantColors} from "../../../styles/AvantColors"
import {UnstyledLink} from "../../generic-components/UnstyledLink"
import {makeStyles} from "@material-ui/core"
import {ReportFilters} from "../../reports/common/models/ReportFilters"
import {BlueReportType} from "../../reports/common/models/BlueReportType"
import {LabelText, SmallLabelText} from "../../../styles/AvantTypography"
import {ReportSubPaths} from "../../reports/ReportSubPaths"

interface TableDataRow extends GroupReportRow {
    tableData: {
        id: number,
    }
}

interface DistrictOrSchoolLinkProps {
    content: string,
    filters: ReportFilters,
    id: number,
    enabled?: boolean,
    printing?: boolean,
    reportType?: BlueReportType,
}

const DistrictOrSchoolLink = ({content, id, filters, enabled, printing, reportType}: DistrictOrSchoolLinkProps) => {
    const styles = makeStyles({
        clickableText: {
            "& *": {
                transition: "all 0.2s",
                fontWeight: "bold",
            },
            "&:hover *": {
                color: AvantColors.avantDarkBlue,
                textDecoration: "underline",
            },
        },
    })()

    let textContents: JSX.Element = <></>
    let link = ""
    if (reportType === BlueReportType.DISTRICT) {
        textContents = (
            <LabelText style={{color: enabled ? "" : AvantColors.REPORT_FONT_COLOR_GRAY_3}}>
                {content}
            </LabelText>
        )
        link = ReportSubPaths.school({...filters, groupId: id})
    }
    if (reportType === BlueReportType.SCHOOL) {
        textContents = (
            <SmallLabelText style={{color: enabled ? "" : AvantColors.REPORT_FONT_COLOR_GRAY_3}}>
                {content}
            </SmallLabelText>
        )

        link = ReportSubPaths.class({...filters, groupId: id})
    }

    if (printing || !enabled) {
        return textContents
    }

    return (
        <UnstyledLink
            to={printing ? "" : link}
            className={styles.clickableText}
        >
            {textContents}
        </UnstyledLink>
    )
}

const columns = (filters: ReportFilters, reportType?: BlueReportType, printing?: boolean): Column<TableDataRow>[] => {
    return [
        {
            title: "School/Location List",
            defaultSort: "desc",
            render: (row: TableDataRow) => <DistrictOrSchoolLink content={row.name} filters={filters} id={row.id} enabled={row.dataAvailable} printing={printing} reportType={reportType} />
        },
        {
            title: "Last Testing Date",
            render: (row: TableDataRow) => TimeUtil.formatMillisToUsDate(row.lastTestingDate),
            cellStyle: (_, rowData: TableDataRow) => {
                return {
                    borderLeft: `1px solid ${AvantColors.REPORT_BACKGROUND_GRAY_DARK}`,
                    color: AvantColors.REPORT_FONT_COLOR_GRAY_3,
                    opacity: rowData.dataAvailable ? 1 : 0.75,
                }
            },
        },
        {
            title: "Data Available in Date Range",
            render: (row: TableDataRow) => row.dataAvailable ? "Yes" : "No",
            cellStyle: (_, rowData: TableDataRow) => {
                return {
                    borderLeft: `1px solid ${AvantColors.REPORT_BACKGROUND_GRAY_DARK}`,
                    color: AvantColors.REPORT_FONT_COLOR_GRAY_3,
                    opacity: rowData.dataAvailable ? 1 : 0.75,
                }
            },
        },
    ]
}

export interface SchoolLocationListProps extends ReportProps {
    reportType?: BlueReportType,
}

export const SchoolLocationList = (props: SchoolLocationListProps) => {
    const [reportTableRows, setReportTableRows] = useState<TableDataRow[]>([])

    useEffect(() => {
        const tableRows: TableDataRow[] = []
        props.adminReportData && props.adminReportData.forEach((row: GroupReportRow, index: number) => {
            row.dataAvailable = false
            if (reportsStore.groupsWithData && reportsStore.groupsWithData.includes(row.id)) {
                row.dataAvailable = true
            }

            tableRows.push({
                ...row,
                tableData: {
                    id: index,
                }
            })
        })

        setReportTableRows(tableRows)
    }, [])

    return (
        <AvantExpansionPanel
            title={"School/Location List"}
            expanded={props.printOptions !== null || undefined}
        >
            <MaterialTable
                columns={columns(props.filters, props.reportType, typeof props.printOptions !== 'undefined')}
                data={reportTableRows}
                options={{
                    selection: false,
                    toolbar: false,
                    paging: false,
                }}
            />
        </AvantExpansionPanel>
    )
}
