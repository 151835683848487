import {Print} from "@material-ui/icons"
import {observer} from "mobx-react"
import React from "react"
import {RouteComponentProps} from "react-router"
import ReactToPrint from "react-to-print"
import {theme} from "../../../../styles/MuiThemes"
import {TimeUtil} from "../../../../util/TimeUtil"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {TakeSearchResult} from "../../../common/take/models/TakeSearchResult"
import {AvantPopperButton} from "../../../generic-components/AvantPopperButton"
import {SkillAverageChart} from "../../common/components/aggregate-charts/SkillAverageChart"
import {BreadcrumbHeader} from "../../common/components/Breadcrumbs"
import {ReportPrintBrand} from "../../common/components/ReportPrintBrand"
import {findFilters} from "../../common/components/ReportsPage"
import {ReportFilters} from "../../common/models/ReportFilters"
import {takePanelStore} from "../../../common/take-panel/TakePanelStore"
import {
    compositeScoreAggregate,
    listeningSkillAggregate,
    readingSkillAggregate,
    speakingSkillAggregate,
    writingSkillAggregate
} from "../../common/models/TakeSkillAggregate"
import {reportsStore} from "../../common/stores/ReportStore"
import {TestingGroupAverages} from "../../district-school/models/ReportSummaryGraphs"
import {GroupData, IndividualReportHeader} from "./IndividualReportHeader"
import {TakePanelSkillReportView} from "./TakePanelSkillReportView"
import {ReportPrintFooter} from "../../common/components/ReportPrintFooter"
import {PanelSkillEnum, PanelSkillOrder} from "../../../../types/types"
import {GroupInfo} from "../../../common/group/models/GroupInfo";

export default class IndividualReportsPage extends React.Component<RouteComponentProps<{ productId: string, groupId: string, takeId: string }>> {
    render() {
        const takeId = Number(this.props.match.params.takeId)
        const filters = findFilters(this.props)
        // @ts-ignore
        const groupInfo: GroupInfo = JSON.parse(this.props.location.state.report)
       return <IndividualReportsContainer takeId={takeId} groupInfo={groupInfo} filters={filters} includeBrand={true}/>
    }
}

interface IndividualReportsContainerProps {
    takeId: number
    groupInfo?: GroupInfo
    filters?: ReportFilters
    print?: boolean
    includeBrand: boolean
}

@observer
export class IndividualReportsContainer extends React.Component<IndividualReportsContainerProps, {averages: TestingGroupAverages, formattedDate: string | undefined, instance: TakeSearchResult | undefined, skillSymbolsInPanel: string[]}> {

    constructor(props: IndividualReportsContainerProps) {
        super(props)

        this.state = {
            averages: this.getEmptyAverages(),
            formattedDate: undefined,
            instance: undefined,
            skillSymbolsInPanel: [],
        }
    }

    // tslint:disable-next-line:no-any
    componentRef?: any

    findInTakeSearchStore = () => {
        return reportsStore.instancesData!!.find((take) => take.takeId === this.props.takeId)
    }

    componentDidMount(): void {
        this.setSkillSymbolsInPanelInState().then(() => {
            this.refreshIndividualReportsData(this.props)
        })
    }

    componentDidUpdate(prevProps: Readonly<IndividualReportsContainerProps>){
        const {takeId} = this.props
        if (takeId !== prevProps.takeId) {
            this.refreshIndividualReportsData(this.props)
        }
    }

    refreshIndividualReportsData = (props: IndividualReportsContainerProps) => {
        const {takeId} = props

        reportsStore.individualReport = undefined
        reportsStore.findIndividualReport(takeId).then( () => {
            this.prepareReportState()
        })
    }

    prepareReportState = () => {
        const instance = this.props.print ? this.findInTakeSearchStore() : reportsStore.individualReport
        const formattedDate = typeof(instance) === 'undefined' ? undefined : TimeUtil.formatMilliToMonthDayYearLong(Number(instance.startTime))

        this.setState({
            averages: this.getAverages(instance),
            instance: instance,
            formattedDate: formattedDate
        })
    }

    getAverages = (instance: TakeSearchResult | undefined) => {
        if (typeof(instance) === 'undefined') {
            // Early return.
            return this.getEmptyAverages()
        }

        // Else...
        const instanceList: TakeSearchResult[] = [instance]
        const skillSymbolsInPanel = this.state.skillSymbolsInPanel

        // TODO remove these aggregators or simplify. This is their last vestige
        const aggregates = [
            readingSkillAggregate(instanceList),
            writingSkillAggregate(instanceList),
            listeningSkillAggregate(instanceList),
            speakingSkillAggregate(instanceList),
        ]
        const compositeAggregate = compositeScoreAggregate(instanceList)

        // Ensures that the skills are in the correct order; this isn't completely necessary as the order is set above in the aggregrates array,
        // but better safe than sorry right?
        aggregates.sort((a,b) => {
            return PanelSkillOrder.indexOf(a.skillSymbol as PanelSkillEnum) - PanelSkillOrder.indexOf(b.skillSymbol as PanelSkillEnum)
        })

        const averages: TestingGroupAverages = {
            scores: [],
            compositeScore: compositeAggregate.score
        }

        aggregates.forEach((a) => {
            // Only include those averages that are actually in this test.
            if (skillSymbolsInPanel.includes(a.skillSymbol)) {
                averages.scores.push({skillName: a.skillName, average: a.averageInSkill})
            }
        })

        return averages
    }

    getEmptyAverages = () => {
        const emptyAverages: TestingGroupAverages = {
            scores: [],
            compositeScore: undefined
        }

        return emptyAverages
    }

    setSkillSymbolsInPanelInState = () => {
        const {takeId} = this.props
        return takePanelStore
            .getTakeSkillReport(takeId)
            .then((report) => {
                // Establish a list of skill symbols that are actually used for the test.
                let skillSymbols = []
                // The key of each report is the same as the skillSymbol property, but that property isn't in the TakePanelSkillReport interface.
                for (const key in report) {
                    if (report[key].selfEvaluation === true) {
                        // Do nothing. We do not include self-evaluation panels in this list so that they're not included in the graph.
                    } else {
                        skillSymbols.push(key)
                    }
                }
                // Save the skill symbol list to the state.
                this.setState({skillSymbolsInPanel: skillSymbols})
            })
    }

    // tslint:disable-next-line:no-any
    setRef = (ref: any) => this.componentRef = ref

    render() {
        // Render a spinner if the instance is empty.
        const instance = this.state.instance
        if (typeof(instance) === 'undefined') {
            // Early return.
            return <AvantCircularProgress/>
        }

        // Else...
        // Pull values from props and state.
        const {filters, print, takeId, includeBrand, groupInfo} = this.props
        const {firstName, lastName, language, code, takePanels, isHandWritten} = instance
        const formattedDate = this.state.formattedDate
        const averages = this.state.averages

        let reportPrintBrand = <></>
        if (includeBrand) {
            reportPrintBrand = <ReportPrintBrand/>
        }

        const groupData: GroupData[] = []

        if (groupInfo) {
            const {parentGroups} = groupInfo
            parentGroups.forEach(group => {
                const gd: GroupData = {
                    id: group.id,
                    type: group.groupType,
                    name: group.name
                }

                groupData.push(gd)
            })
        }

        // Render the report.
        return (
            <div style={{maxWidth: 1100, margin: "0 auto"}} className={"individual_reports_container"}>
                {!print && filters && (
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <BreadcrumbHeader
                            groupInfo={groupInfo}
                            individual={`${firstName} ${lastName}`}
                            filters={filters}
                        />
                        <ReactToPrint
                            trigger={() =>
                                <AvantPopperButton
                                    title={<Print/>
                                    }
                                />}
                            content={() => this.componentRef}
                            pageStyle={"@media print {margin: 0.5in}"}
                        />
                    </div>
                )}
                <div ref={this.setRef} style={{display: "flex", flexDirection: "column"}}>
                    {reportPrintBrand}
                    <IndividualReportHeader
                        firstName={firstName}
                        lastName={lastName}
                        language={language}
                        loginName={code}
                        date={formattedDate}
                        groupData={groupData}
                    />
                    <div style={{padding: theme.spacing(1, 0), margin: 0}}>
                        <SkillAverageChart
                            averages={averages}
                        />
                    </div>
                    <TakePanelSkillReportView
                        takePanels={takePanels}
                        handwritten={isHandWritten}
                        takeId={takeId}
                    />
                    <ReportPrintFooter/>
                </div>
            </div>
        )

    }
}
