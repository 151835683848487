import * as React from "react"
import MaterialTable, {Column} from "material-table"
import {Icon, IconButton, Switch, Typography, Tooltip, Grid} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import StopIcon from "@material-ui/icons/Stop"
import {
    SSOLicenseCount,
    SsoTeacherClass
} from "../../../../types/types"
import {LicenseSelect} from "./LicenseSelect"
import {ChangeEvent, RefObject, useState} from "react"
import {Person} from "@material-ui/icons"
import {CustomTeacherView} from "./dialogs/CustomTeacherView"
import productPackageStore from "../../../common/ProductPackage/ProductPackageStore";

interface ClassListProps {
    classList: SsoTeacherClass[],
    tableRef: RefObject<MaterialTable<SsoTeacherClass>>,
    viewReports: (event: any, row:any) => void,
    startContinueTest: (event: any, row:any) => void,
    stopTest: (event: any, row:any) => void,
    handleProductChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, row: SsoTeacherClass, tableId: number) => void,
    toggleHandwritten: (event: ChangeEvent<HTMLInputElement>, row: SsoTeacherClass, tableId: number) => void,
    updateHandwritten: (row: SsoTeacherClass, newValue: boolean, tableId: number) => void,
    isWritingInPackage: (packageVal?: string | number) => boolean
    licenses_sold: SSOLicenseCount[],
    handleSelectionChange: (rows: SsoTeacherClass[]) => void
}

export const ClassList: React.FC<ClassListProps> = (props) => {
    const classList = props.classList ? props.classList : []
    const [teacherOpen, setTeacherOpen] = useState(false)
    const [selectLoginId, setSelectLoginId] = useState(-1)

    const handleCustomTeacherViewClose = () => {
        setTeacherOpen(false)
    }
   React.useEffect(()=>{
     productPackageStore.initialize()
   },[])
    const selectionProps = (rowData: SsoTeacherClass) => {
        return {
            disabled: rowData.logins !== null,
            color: "secondary"
        }
    }
    const isAllowHidden = (rowData: SsoTeacherClass): boolean => {
        if (rowData.logins !== null && rowData.logins.allowTest === false) { return false }
        return true
    }
    const isDisallowHidden = (rowData: SsoTeacherClass): boolean => {
        if (rowData.logins !== null && rowData.logins.allowTest === true) { return false }
        return true
    }
    const isViewReportHidden = (rowData: SsoTeacherClass): boolean => {
        return (rowData.logins === undefined || rowData.logins === null || !rowData.logins.testCode)
    }
    const setStatus = (rowData: SsoTeacherClass | null): React.ReactFragment => {
        if (rowData === null) { return <></> }
        if (rowData.logins === null) {
            switch (rowData.status) {
                case ("NEEDS_DISTRICT_SETUP") :
                    return <>Needs product reservations.</>
                case ("DISTRICT_SETUP_NEED_CLASSROOM_SETUP") :
                    return <>Needs class setup.</>
                case ("TESTING_READY") :
                    return <>Testing Paused<Typography><Icon>play_arrow</Icon></Typography></>
                default:
                    return ""
            }
        } else {
            if (rowData.logins.allowTest === false) {
                return <>Testing Paused</>
            }
            if (rowData.logins.allowTest === true) {
                return <>Testing Ready</>
            }
        }
        return ""
    }

    const handleProductChange = (e:ChangeEvent<{name?:string, value:unknown}>, rowData: any, index: number): void => {
        // Update the value in the state variable, so that it renders correctly.
        rowData.productName  = e.target.value

        // Send the new value to the parent to be saved.
        props.handleProductChange(e, rowData, index)

        // If a non-Writing package is selected...
        if (props.isWritingInPackage(rowData.productName) === false) {
            // Send the new value (false) to the parent to be saved.
            props.updateHandwritten(rowData, false, rowData.tableData.id)
        }
    }

    const productDropdown = (rowData: any): React.ReactFragment => {
        if (rowData.logins && rowData.productName) {
            return (<>{productPackageStore.productPackages.find((pp) => pp.code === rowData.productName)?.longName || productPackageStore.productPackageToName[rowData.productName]}</>)
        } else {
            if (rowData.licenses_sold) {
                return (
                    <LicenseSelect
                        rowData={rowData}
                        tableId={rowData.tableData.id}
                        selectedPackage={rowData.productName}
                        handleChange={handleProductChange}
                        licenses_sold={rowData.licenses_sold}
                    />
                )
            } else {
                return (<Typography>No Licenses available</Typography>)
            }
        }
    }

    const handwrittenControl = (rowData: any): React.ReactFragment => {
        if (rowData.logins) {
            if (rowData.logins.handwritten) {
                return (<>Using Handwritten</>)
            } else {
                return (<></>)
            }
        } else {
            return (
                <Switch
                    onChange={(e) => {
                        if (props.isWritingInPackage(rowData.productName)) {
                            // Update the value in the state variable, so that it renders correctly.
                            rowData.handwritten = e.target.checked

                            // Send the new value to the parent to be saved.
                            props.toggleHandwritten(e, rowData, rowData.tableData.id)
                        }
                    }}
                    checked={rowData.handwritten}
                    disabled={rowData.isHandwrittenSold === false || props.isWritingInPackage(rowData.productName) === false}
                    inputProps={{"aria-label": "Use handwritten form for written parts of the test."}}
                    color={"primary"}
                />
            )
        }
    }

    const testCode = (rowData: any): React.ReactFragment => {
        return <>
            {rowData.logins && <>{rowData.logins.testCode}</>}
        </>
    }

    const actions = (rowData: any): React.ReactFragment => {
        return <Grid container spacing={0}>
            {!isViewReportHidden(rowData) && <Grid item={true} xs={6}><Tooltip title={"View Test Results"}>
                <IconButton onClick={
                    (event) => props.viewReports(event, rowData)
                }>
                    <SearchIcon />
                </IconButton>
            </Tooltip></Grid>}
            {!isAllowHidden(rowData) && <Grid item={true} xs={6}><Tooltip title={"Allow Students to take the test"}>
                <IconButton onClick={
                    (event) => props.startContinueTest(event, rowData)
                }>
                    <PlayArrowIcon />
                </IconButton>
            </Tooltip></Grid>}
            {!isDisallowHidden(rowData) && <Grid item={true} xs={6}><Tooltip title={"Disallow Students to take the test"}>
                <IconButton onClick={
                    (event) => props.stopTest(event, rowData)
                }>
                    <StopIcon />
                </IconButton>
            </Tooltip></Grid>}
            <Grid item={true} xs={6}>
            <Tooltip title={"View Teacher for this Class"}>
                <IconButton onClick={
                    (event) => {
                        setSelectLoginId(rowData.logins.id)
                        setTeacherOpen(true)
                    }
                }>
                    <Person />
                </IconButton>
            </Tooltip>
            </Grid>
        </Grid>
    }

    // Column data needs to be a state property to retain sorting and filter data between re-renders.
    const initialColumns: Array<Column<any>> = [
        {title: "Class", field: "name"},
        {title: "Course Id", field: "courseId"},
        {
            title: "School",
            field: "schoolName",
            defaultSort: "asc",
            customSort: (a, b) => (a.schoolName.toLowerCase() > b.schoolName.toLowerCase() ? 1 : (a.schoolName.toLowerCase() < b.schoolName.toLowerCase() ? -1 : ( a.name.toLowerCase() > b.name.toLowerCase() ? 1 : ( a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0 ) )) )
        },
        {title: "Class Count", field: "count"},
        // To be restored later (see AVANT-2762):
        //{title: "Consumed", field: "countConsumed"},
        {title: "Est. Testing Date", field: "estTestingDate", type: "date"},
        {title: "Product", field: "productName", render: productDropdown},
        {title: "Language", field: "language"},
        {title: "Handwritten", field: "handwritten", render: handwrittenControl, filtering: false},
        {title: "Test Code", field: "logins.testCode", render: testCode},
        {title: "Status", field: "status", render: setStatus},
        {title: "Actions", field: "actions", render: actions, filtering: false},
    ]
    const [columns, setColumns] = useState(initialColumns)
    // If setColumns is not invoked (even if just in a statement that will never evaluate), we'll get a TypeScript error.
    if (false) {setColumns(initialColumns)}

    return (
        <>
        <MaterialTable
            tableRef={props.tableRef}
            title="Class List"
            columns={columns}
            data={classList}
            options={{
                selection: true,
                filtering: true,
                search: false,
                sorting: true,
                showTitle: true,
                toolbar: true,
                pageSizeOptions: [5,10,20,50,100],
                padding: "dense",
                selectionProps: selectionProps,
                thirdSortClick: false,
            }}
            onSelectionChange={props.handleSelectionChange}
        />
            <CustomTeacherView
                open={teacherOpen}
                onClose={handleCustomTeacherViewClose}
                loginId={selectLoginId}
            />
        </>
    )
}
