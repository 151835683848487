import {Button, Grid} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as lodash from "lodash"
import {observable} from "mobx"
import {observer} from "mobx-react"
import moment from "moment"
import * as React from "react"
import {useEffect} from "react"
import {H1Text, H2Text} from "../../../styles/AvantTypography"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../../util/Constants"
import {log} from "../../../util/Logging"
import {authStore} from "../../common/authentication/AuthStore"
import {groupMasterStore} from "../../common/group/GroupMasterStore"
import {AssessmentGroupInfo, GroupMaster} from "../../common/group/models/GroupMaster"
import {AvantCircularProgress, CircularProgressSize} from "../../common/loaders/AvantCircularProgress"
import {productStore} from "../../common/products/ProductStore"
import {AvantExpansionPanel} from "../../generic-components/AvantExpansionPanel"
import {GetApp} from "@material-ui/icons"
import {PanelSkill, TestTakerSortableTable} from "../class/components/TestTakerSortableTable"
import {ReportTakeRow} from "../class/models/ReportTakeRow"
import {
    AvantNewDateRangePickerHelpers,
    PlaceSearchFilters,
    PlaceTestTakerInstanceReport
} from "../common/components/AvantNewDatePicker/AvantNewDateRangePickerHelpers"
import {reportsAssessmentStartValue, reportsRecentActivityValue} from "../common/components/FilterAndPrintOptions"
import {LevelKey} from "../common/components/level-key/LevelKey"
import TestGroupInstanceSearch from "../common/components/TestGroupInstanceSerch"
import ApiServicePlace from "./ApiServicePlace"
import PlaceReportUtils from "./utils/PlaceReportUtils"
import {PLACEReportPrintMenu} from "./PLACEReportPrint"
import {PrintOptions} from "../common/components/PrintViewContainer"
import {LevelKeyContent} from "../common/components/level-key/LevelKeyContent"
import {TableDataResults} from "../class/models/TableDataResults"
import {reportsStore} from "../common/stores/ReportStore"
import { TestControls } from "../class/components/TestControls"
import {Moment} from "moment/moment"
import {DateRange} from "@mui/x-date-pickers-pro";
import {DatePickerOptions} from "../common/components/AvantNewDatePicker/AvantControlledDateRangePicker"

const usePLACEReportPageStyles = makeStyles(theme => ({
    root: {
        minWidth: 1356,
        // TODO: Remove the below value when we remove the link to classic reports
        marginTop: 50,
    },
    titleContainer: {
        paddingBottom: theme.spacing(2),
        display: "flex",
        flexGrow: 1,
    },
    titleControlContainer: {
        display: "flex",
        marginLeft: "auto",
        justifyContent: "flex-start"
    },
    searchComponentContainer: {
        paddingBottom: theme.spacing(2),
    },
    testInstanceListContainerTextOnly: {
        minHeight: 480,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    printAndDownloadButtons: {
        marginLeft: theme.spacing(2)
    }
}))

export interface LanguageList {
    id: number,
    name: string
}

export const placeReportStore: {ReportList: PlaceTestTakerInstanceReport[]} = observable({
    ReportList: []
})

export const findPLACEIndividualReport = (takeId: number): string => {
    const reportData = placeReportStore.ReportList.find(report => report.takeid === takeId)
    const individualReport = reportData ? PlaceReportUtils.buildPlaceIndividualReport(reportData) : null
    return JSON.stringify(individualReport)
}

export const PLACEReportPage: React.FC<{printOptions?: PrintOptions}> = observer(({printOptions}) => {

    const NEW_SEARCH_TEXT: string = "Please Select from the search options and press search"
    const AFTER_FIRST_SEARCH_TEXT: string = "No Data Available. Adjust your search options and click on SEARCH to retry."

    const classes = usePLACEReportPageStyles()

    const [showTable, setShowTable] = React.useState(false)
    const [searchPressed, setSearchPressed] = React.useState(false)
    const [waitingForResults, setWaitingForResults] = React.useState(false)
    const [listOfLocations, setListOfLocations] = React.useState<GroupMaster[]>([])
    const [listOfLanguages, setListOfLanguages] = React.useState<LanguageList[]>([])

    const [locationSelectList, setLocationSelectList] = React.useState<React.ReactNode[]>([])
    const [languageSelectList, setLanguageSelectList] = React.useState<React.ReactNode[]>([])

    const [reportInstanceList, setReportInstanceList] = React.useState<TableDataResults>({
        reportTakeRows: [],
        availableSkills: []
    })
    const [skillList, setSkillList] = React.useState<PanelSkill[]>([])

    const [filters, setFilters] = React.useState<PlaceSearchFilters>({
        selectedLocationId: (groupMasterStore.defaultGroup != null && groupMasterStore.defaultGroup.id != null)
            ? groupMasterStore.defaultGroup.id
            : 0,
        locationIdList: (groupMasterStore.defaultGroup != null && groupMasterStore.defaultGroup.id != null)
            ? groupMasterStore.defaultGroup.testingGroupInfo.map((assessInf: AssessmentGroupInfo) => {
                return assessInf.loginId
            })
            : [0],
        contentAreaId: 0,
        firstName: "",
        lastName: "",
        loginName: "",
        dateRangeFilters: {
            startDate: null,
            endDate: null,
            groupId: groupMasterStore.defaultGroup != null
            ? groupMasterStore.defaultGroup.id
            : -1,
            productId: (productStore.loginProduct != null && productStore.loginProduct.productId != null)
            ? productStore.loginProduct.productId
            : -1,
            assessmentStart: true,
            recentActivity: false
        }
    })

    const [reportLoading, setReportLoading] = React.useState(false)

    useEffect(() => {

        if (groupMasterStore.groupInfo == null && groupMasterStore.defaultGroup != null) {
            groupMasterStore.findGroupInfo(groupMasterStore.defaultGroup.id)
        }

        if (listOfLocations.length === 0) {
            makeLocationList()
        }

        if (skillList.length === 0) {
            getAvailablePanelSkills()
        }

        if (reportsStore.selectedTakeIds.length > 0) {
            reportsStore.selectedTakeIds = []
        }

    }, [])

    useEffect(() => {
        if (printOptions == null) {
            setWaitingForResults(false)
        } else {
            setWaitingForResults(true)
        }

        if (printOptions && skillList.length > 0) {
            getTableDataResults(placeReportStore.ReportList)
        }
    }, [skillList])

    const getInstanceList = async () => {

        setWaitingForResults(true)

        let newFilters = filters
        if (filters.locationIdList.length < 1 && groupMasterStore.defaultGroup !== undefined && groupMasterStore.defaultGroup.testingGroupInfo.length > 0) {
            newFilters.locationIdList = groupMasterStore.defaultGroup.testingGroupInfo.map((gm)=> gm.loginId)
            setFilters({...filters, locationIdList: newFilters.locationIdList})
        }

        const list: PlaceTestTakerInstanceReport[] = await ApiServicePlace.getTestInstanceList(newFilters)

        placeReportStore.ReportList = list

        getTableDataResults(list)

    }

    const clearSearchFilters = () => {
        setFilters({
            selectedLocationId: listOfLocations.length === 1
                ? listOfLocations[0].id
                : 0,
            contentAreaId: listOfLanguages.length === 1 ? listOfLanguages[0].id : 0,
            locationIdList: (groupMasterStore.defaultGroup != null && groupMasterStore.defaultGroup.id != null)
                ? groupMasterStore.defaultGroup.testingGroupInfo.map((assessInf: AssessmentGroupInfo) => {
                    return assessInf.loginId
                })
                : [0],
            firstName: "",
            lastName: "",
            loginName: "",
            dateRangeFilters: {
                startDate: null,
                endDate: null,
                groupId: groupMasterStore.defaultGroup != null
                    ? groupMasterStore.defaultGroup.id
                    : -1,
                productId: (productStore.loginProduct != null && productStore.loginProduct.productId != null)
                    ? productStore.loginProduct.productId
                    : -1,
                assessmentStart: true,
                recentActivity: false
            }
        })

        setReportInstanceList({
            reportTakeRows: [],
            availableSkills: []
        })

        setShowTable(false)
        setSearchPressed(false)
        setWaitingForResults(false)
    }

    const makeSearchRequest = () => {
        log.debug(`filters: ${JSON.stringify(filters)}`)
        getInstanceList()
        // log.debug(`Got Instance List result, resolving promise! ${prettyJson(result)}`)
        setSearchPressed(true)
        setShowTable(false)
    }

    const getAvailablePanelSkills = async () => {

        if (groupMasterStore.defaultGroup != null) {
            const listOfChildGroups: number[] = (listOfLocations.length > 0)
                ? listOfLocations.map((loc: GroupMaster) =>  loc.id)
                : [groupMasterStore.defaultGroup.id]

            const result: PanelSkill[] = await ApiServicePlace.getAllSkillsForInstancesSearched(listOfChildGroups)

            setSkillList(result)
        } else {
            log.error("No default group!!!")
        }

    }

    const setRangeFromDatePicker = (range: DateRange<Moment>, options: DatePickerOptions|null) => {
        const curFilters = filters.dateRangeFilters
        if (range[0] != null) {
            curFilters.startDate = range[0].valueOf()
        } else {
            curFilters.startDate = null
        }
        if (range[1] != null) {
            curFilters.endDate = range[1].valueOf()
        } else {
            curFilters.endDate = null
        }


        setFilters({...filters, dateRangeFilters: curFilters})
    }

    const filterChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        log.debug(`FilterChanger Name: ${name}`)
        switch (name) {
            case "firstName": {
                setFilters({...filters, firstName: value })
                break
            }
            case "lastName": {
                setFilters({...filters, lastName: value })
                break
            }
            case "loginName": {
                setFilters({...filters, loginName: value })
                break
            }
            case "Language": {
                setFilters({...filters, contentAreaId: Number(value)})
                break
            }
            case "Location": {
                setFilters({...filters, selectedLocationId: Number(value)})
                break
            }
            case reportsAssessmentStartValue: {
                const curFilters = filters.dateRangeFilters
                log.error(`Value = ${value}`)
                curFilters.assessmentStart = !curFilters.assessmentStart
                setFilters({...filters, dateRangeFilters: curFilters })
                break
            }
            case reportsRecentActivityValue: {
                const curFilters = filters.dateRangeFilters
                log.error(`Value = ${value}`)
                curFilters.assessmentStart = !curFilters.assessmentStart
                setFilters({...filters, dateRangeFilters: curFilters })
                break
            }

            default: {
                log.error("Hit the default switch case in `filterChanger` ")
            }
        }
    }

    const makeLanguageList = () => {
        if (groupMasterStore.defaultGroup != null && groupMasterStore.defaultGroup.id) {
            const availLang: LanguageList[] =
                groupMasterStore.defaultGroup.testingGroupInfo.map((tgi: AssessmentGroupInfo) => {
                    return {
                        id: tgi.contentAreaId,
                        name: CONTENT_AREA_ID_TO_LANGUAGE_NAME[tgi.contentAreaId]
                    }
                }
            )

            const setOfLang = lodash.uniqBy(availLang, "id")

            setListOfLanguages(setOfLang)

            const menuItems = AvantNewDateRangePickerHelpers.makeSelectDropDownOptions(setOfLang, false)

            setLanguageSelectList(menuItems)

            if (setOfLang.length === 1) {
                // Only 1 language, so lets set it as the default
                setFilters({...filters, contentAreaId: setOfLang[0].id })
            } else {
                // Multiple languages, set the default selection to all languages
                setFilters({...filters, contentAreaId: 0})
            }

            setWaitingForResults(false)
        }
    }

    const makeLocationList = async () => {

        if (groupMasterStore.defaultGroup != null && groupMasterStore.defaultGroup.id) {

            try {
                // This is to see if there are any child groups, if not, you group IS the group
                const locations = await ApiServicePlace.getChildGroups(groupMasterStore.defaultGroup.id)
                if (locations != null) {
                    log.debug(`Location Length: ${locations.length}`)
                    const sortedListOfGMs: GroupMaster[] = locations.sort((gm1: GroupMaster, gm2: GroupMaster) => {
                        if (gm1.name.toLowerCase() > gm2.name.toLowerCase()) {
                            return 1
                        }
                        if (gm1.name.toLowerCase() < gm2.name.toLowerCase()) {
                            return -1
                        }
                        return 0
                    })

                    const actualList = sortedListOfGMs.length < 1 ? [groupMasterStore.defaultGroup] : locations

                    const elementList = AvantNewDateRangePickerHelpers.makeSelectDropDownOptions(actualList, true)

                    setLocationSelectList(elementList)

                    if (locations.length === 1) {
                        setFilters({...filters, selectedLocationId: locations[0].id})
                        setListOfLocations([groupMasterStore.defaultGroup])
                        makeLanguageList()
                    } else if (locations.length === 0) {
                        setFilters({...filters, selectedLocationId: groupMasterStore.defaultGroup.id})
                        setListOfLocations([groupMasterStore.defaultGroup])
                        makeLanguageList()
                    } else {
                        const newFilter = filters
                        newFilter.selectedLocationId = 0
                        setFilters({...newFilter, selectedLocationId: 0})
                        setListOfLocations(locations)
                        makeLanguageList()
                    }
                } else {
                    setListOfLocations([])
                    log.error("This is an error state from makeLocationList")
                }
            } catch (e) {
                const err = e as Error
                log.error(err.message)
                setListOfLocations([])
            }
        }

    }

    const getTableDataResults = (resultList: PlaceTestTakerInstanceReport[]) => {
        // TODO move conversion to the backend...
        const instList: ReportTakeRow[] = PlaceReportUtils.convertPlaceInstanceReportToReportTakeRow(resultList)

        const resultSet: TableDataResults = {
            reportTakeRows: instList,
            availableSkills: skillList
        }

        setReportInstanceList(resultSet)

        if (instList.length > 0) {
            setShowTable(true)
        } else {
            setShowTable(false)
            // This removes the spinner if nothing found
            setWaitingForResults(false)
        }
    }

    const getCsvReport = () => {
        const result = ApiServicePlace.getCsv(setReportLoading, filters)
        if (result != null) {
            log.debug("Not Null!")
        }
    }

    const getCustomerGroupName = (): string => {

        const noCustName = "No Customer Group Master"

        if (groupMasterStore.groupInfo != null && groupMasterStore.defaultGroup != null && groupMasterStore.defaultGroup.id != null) {
            const cust = (authStore.auth != null && authStore.auth.userType === "T")
                ? groupMasterStore.groupInfo.parentGroups.find(group => group.groupType === "C")
                : groupMasterStore.defaultGroup

            if (cust != null && cust.name != null) {
               return cust.name
           } else {
               return noCustName
           }
        } else {
            return noCustName
        }
    }

    return (
        <div className={classes.root} data-tst-id={"PlaceReportsContainer"}>
            <div className={classes.titleContainer} data-tst-id={"placeReportTitleContainer"}>

                <H1Text>{getCustomerGroupName()}</H1Text>

                <div className={classes.titleControlContainer} data-tst-id={"placeReportTitleContainer-ControlContainer"}>
                    {printOptions != null ?
                        <LevelKeyContent/> :
                        <>
                            <LevelKey narrowPadding={true}/>
                            <PLACEReportPrintMenu
                                filters={filters.dateRangeFilters}
                                disableMenu={reportInstanceList.reportTakeRows.length < 1}
                            />

                            <Button
                                className={classes.printAndDownloadButtons}
                                variant={"outlined"}
                                onClick={getCsvReport}
                                disabled={reportInstanceList.reportTakeRows.length < 1}
                                endIcon={reportLoading ? <AvantCircularProgress size={CircularProgressSize.SMALL} /> : <GetApp />}
                            >
                                Test Taker Data
                            </Button>
                        </>
                    }
                </div>
            </div>
            {!printOptions &&
                <div className={classes.searchComponentContainer} data-tst-id={"SearchComponentContainer"}>
                    <TestGroupInstanceSearch
                        clearParentSearchFilters={clearSearchFilters}
                        submitParentSearchFilters={makeSearchRequest}
                        parentFilters={filters}
                        setParentFilters={filterChanger}
                        searchPressed={searchPressed}
                        jsxLocationElementList={locationSelectList}
                        jsxLanguageElementList={languageSelectList}
                        setParentDateRange={setRangeFromDatePicker}
                    />
                </div>
            }
            {groupMasterStore.groupInfo && (
                    <Grid item={true} xs={12}>
                    <TestControls groupInfo={groupMasterStore.groupInfo}/>
                    </Grid>
                    )}
            <AvantExpansionPanel title={"Test Taker List"}>

                { !showTable
                    ? (
                        <div className={classes.testInstanceListContainerTextOnly}>
                            { waitingForResults
                                ? (
                                    <AvantCircularProgress />
                                  )
                                : (

                                    <H2Text>
                                        {
                                            searchPressed
                                                ? AFTER_FIRST_SEARCH_TEXT
                                                : NEW_SEARCH_TEXT
                                        }
                                    </H2Text>
                                  )
                            }
                        </div>
                    )
                    : (
                        <div>
                            <TestTakerSortableTable
                                filters={{
                                    groupId: groupMasterStore!!.defaultGroup!!.id,
                                    productId: 3,
                                    assessmentStart: false,
                                    recentActivity: false,
                                    startDate: moment(moment.now()),
                                    endDate: moment(moment.now())
                                }}
                                printing={printOptions != null}
                                data={reportInstanceList}
                                isMultiLanguage={undefined}
                                isTestTakerEditingAllowed={authStore.isTestTakerEditingAllowed()}
                            />
                        </div>
                    )
                }
            </AvantExpansionPanel>
        </div>
    )
})
