import React from "react"
import {Alert, Col, Container, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap"
import {isNullOrUndefined} from "util"

import {groupMasterStore} from "../../app/common/group/GroupMasterStore"
import {GroupMaster, GroupType} from "../../app/common/group/models/GroupMaster"
import ApiService from "../../services/ApiService"
import {ApiErrorResponse, SelectOption, UserDataFilters, UserGroups, UserReportData} from "../../types/types"
import {SUPPORT_MESSAGE} from "../../util/Constants"
import {GroupId} from "../../validation/ValidPrimaryKey"
import {Button} from "@material-ui/core";
// import Button from "../Button/Button"

interface AdvanceReportSearchProps {
    loginId: number
    userType: string
    getUserData: (filters: UserDataFilters) => Promise<UserReportData[]>
    setHasUserData: (hasUserData: boolean) => void
}

interface AdvanceReportSearchState {
    error: boolean | string
    ordering: string
    username?: string
    firstName?: string
    lastName?: string
    showNoUsersModal: boolean
    languageId?: number
    stateId?: number
    districtId?: number
    schoolId?: number
    states?: GroupMaster[]
    districts?: GroupMaster[]
    schools?: GroupMaster[]
    stateNamesById?: Map<number, string>
    districtNamesById?: Map<number, string>
    schoolNamesById?: Map<number, string>
}

export default class AdvanceReportSearch extends React.Component<AdvanceReportSearchProps, AdvanceReportSearchState> {
    private languageOptions = [
        {
            value: -1,
            label: "All"
        },
        {
            value: 18,
            label: "Arabic"
        },
        {
            value: 6,
            label: "Chinese Simplified"
        },
        {
            value: 3,
            label: "French"
        },
        {
            value: 4,
            label: "German"
        },
        {
            value: 9,
            label: "Hebrew"
        },
        {
            value: 1,
            label: "Japanese"
        },
        {
            value: 21,
            label: "Russian"
        },
        {
            value: 2,
            label: "Spanish"
        }
    ]

    constructor(props: AdvanceReportSearchProps) {
        super(props)
        this.state = {
            error: false,
            ordering: "username:true",
            showNoUsersModal: false
        }
    }

    componentDidMount() {
        if (this.props.userType === "A") {
            ApiService
                .getGroupsByType(GroupType.STATE)
                .then((states: GroupMaster[]) => {
                    states.sort((a: GroupMaster, b: GroupMaster) => {
                        return a.name.localeCompare(b.name)
                    })
                    const stateNamesById = new Map<number, string>()
                    states.forEach((state: GroupMaster) => {
                        stateNamesById.set(state.id, state.name)
                    })
                    this.setState({states, stateId: undefined, stateNamesById})
                })
                .catch((error: ApiErrorResponse) => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                    throw new Error(`Failed to get states. ${error.response.data.error}`)
                })
        } else if (this.props.userType === "D") {
            this.getDistrictSchools(this.props.loginId)
        }
    }

    getDistrictSchools = (loginId: number) => {
        ApiService.getGroupsForUser(loginId).then(
            (authUserGroups: UserGroups) => {
                if (authUserGroups.districtId !== undefined) {
                    groupMasterStore.getSubGroupsForGroup(new GroupId(authUserGroups.districtId)).then(
                        (groups: GroupMaster[]) => {
                            const schoolSet = new Set<GroupMaster>()
                            groups.forEach((group: GroupMaster) => {
                                let hasSchool = false
                                schoolSet.forEach((school: GroupMaster) => {
                                    if (school.id === group.id) {
                                        hasSchool = true
                                    }
                                })
                                if (!hasSchool) {
                                    schoolSet.add(group)
                                }
                            })
                            const schools: GroupMaster[] = Array.from(schoolSet).sort((a: GroupMaster, b: GroupMaster) => {
                                return a.name.localeCompare(b.name)
                            })
                            schools.unshift({
                                id: -1,
                                name: "All",
                                groupType: GroupType.SCHOOL,
                                defaultGroup: undefined,
                                canView: undefined,
                                testingGroupInfo: [],
                                groupId: 0

                            })
                            this.setState({schools, schoolId: -1})
                        },
                        (error: ApiErrorResponse) => {
                            this.setState({
                                error: SUPPORT_MESSAGE
                            })
                            throw new Error(`Failed to get groups for user. 
                                    ${error.response.data.error}`)
                        }
                    )
                }
            },
            (error: ApiErrorResponse) => {
                this.setState({
                    error: SUPPORT_MESSAGE
                })
                throw new Error(`Failed to get groups for user. ${error.response.data.error}`)
            }
        )
    }

    handleStateChange = (e: any) => {
        if (!isNullOrUndefined(e.target.value)) {
            const stateId: number = parseInt(e.target.value, 10)
            if (stateId) {
                this.getDistricts(new GroupId(stateId))
            } else {
                this.setState({stateId: undefined, districts: undefined, schools: undefined})
            }
        }
    }

    getDistricts = (stateId: GroupId) => {
        groupMasterStore.getSubGroupsForGroup(stateId).then((groups: GroupMaster[]) => {
            const districts: GroupMaster[] = []
            groups.forEach((group: GroupMaster) => {
                if (group.groupType === GroupType.DISTRICT) {
                    districts.push(group)
                }
            })
            districts.sort((a: GroupMaster, b: GroupMaster) => {
                        return a.name.localeCompare(b.name)
                    })
            const districtNamesById = new Map<number, string>()
            districts.forEach((district: GroupMaster) => {
                districtNamesById.set(district.id, district.name)
            })
            this.setState({
                stateId: stateId.value(),
                districtId: undefined,
                districts,
                schools: undefined,
                districtNamesById
            })
        })
    }

    handleDistrictChange = (e: any) => {
        if (!isNullOrUndefined(e.target.value)) {
            const districtId = parseInt(e.target.value, 10)
            if (districtId) {
                this.getSchools(new GroupId(districtId))
            } else {
                this.setState({districtId, schools: undefined, schoolId: undefined})
            }
        }
    }

    getSchools = (districtId: GroupId) => {
        groupMasterStore.getSubGroupsForGroup(districtId).then((groups: GroupMaster[]) => {
            const schools: GroupMaster[] = []
            groups.forEach((group: GroupMaster) => {
                if (group.groupType === GroupType.SCHOOL) {
                    schools.push(group)
                }
            })
            schools.sort((a: GroupMaster, b: GroupMaster) => {
                return a.name.localeCompare(b.name)
            })
            const schoolNamesById = new Map<number, string>()
            schools.forEach((school: GroupMaster) => {
                schoolNamesById.set(school.id, school.name)
            })
            if (schools.length > 0) {
                this.setState({
                    districtId: districtId.value(),
                    schools,
                    schoolId: undefined
                })
            }
        })
    }

    handleSchoolChange = (e: any) => {
        if (!isNullOrUndefined(e.target.value)) {
            const schoolId = parseInt(e.target.value, 10)
            this.setState({schoolId})
        }
    }

    handleUsernameChange = (e: any) => this.setState({username: e.target.value})

    handleFirstNameChange = (e: any) => this.setState({firstName: e.target.value})

    handleLastNameChange = (e: any) => this.setState({lastName: e.target.value})

    handleOrderingChange = (event: any) => this.setState({ordering: event.target.value})

    handleLanguageChange = (event: any) => {
        const languageId: number = parseInt(event.currentTarget.value, 10)
        this.setState({languageId})
    }

    getUserData = (page: number) => {
        const filters: UserDataFilters = {
            username: this.state.username && this.state.username.length > 0 ? this.state.username : undefined,
            firstName: this.state.firstName && this.state.firstName.length > 0 ? this.state.firstName : undefined,
            lastName: this.state.lastName && this.state.lastName.length > 0 ? this.state.lastName : undefined,
            stateId: this.state.stateId && this.state.stateId !== -1 ? this.state.stateId : undefined,
            districtId: this.state.districtId && this.state.districtId !== -1 ? this.state.districtId : undefined,
            schoolId: this.state.schoolId && this.state.schoolId !== -1 ? this.state.schoolId : undefined,
            ordering: this.state.ordering && this.state.ordering.length > 0 ? this.state.ordering : undefined,
            languageId: this.state.languageId && this.state.languageId !== -1 ? this.state.languageId : undefined,
            page
        }
        this.props.getUserData(filters).then(() => {
            this.props.setHasUserData(true)
        })
    }

    renderSearch = (): React.ReactNode => {
        return (
            <div style={{
                border: "none"
            }}
                 className="list-group-item license-filters">
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormLabel>Filter by First Name</FormLabel>
                                    <FormControl
                                        type="text"
                                        placeholder=""
                                        value={this.state.firstName}
                                        onChange={this.handleFirstNameChange}
                                        data-tst-id="firstName"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormLabel>Filter by Last Name</FormLabel>
                                    <FormControl
                                        type="text"
                                        placeholder=""
                                        value={this.state.lastName}
                                        onChange={this.handleLastNameChange}
                                        data-tst-id="lastName"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <FormLabel>Filter by Username</FormLabel>
                            <FormControl
                                type="text"
                                placeholder=""
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                data-tst-id="username"
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Filter by Language</FormLabel>
                            <FormControl
                                as="select"
                                placeholder="select"
                                onChange={this.handleLanguageChange}
                                data-tst-id="languages"
                                value={this.state.languageId ? this.state.languageId.toString() : undefined}
                            >
                                {this.languageOptions.map((language: SelectOption) => {
                                    return (
                                        <option key={language.value} value={language.value}>
                                            {language.label}
                                        </option>
                                    )
                                })}
                            </FormControl>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Sort by</FormLabel>
                            <FormControl
                                as="select"
                                placeholder=""
                                value={this.state.ordering}
                                onChange={this.handleOrderingChange}
                                data-tst-id="sort-by"
                            >
                                <option value="username:true">Username A to Z</option>
                                <option value="username:false">Username Z to A</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        {this.state.states && (
                            <>
                                <FormGroup>
                                    <FormLabel>Filter by State</FormLabel>
                                    <FormControl
                                        as="select"
                                        placeholder=""
                                        value={this.state.stateId ? this.state.stateId.toString() : undefined}
                                        onChange={this.handleStateChange}
                                        data-tst-id="state"
                                    >
                                        <option value={""}/>
                                        {this.state.states &&
                                        this.state.states.map((state: GroupMaster) => {
                                            return (
                                                <option key={state.id} value={state.id}>
                                                    {state.name}
                                                </option>
                                            )
                                        })}
                                    </FormControl>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel>Filter by District</FormLabel>
                                    <FormControl
                                        as="select"
                                        placeholder=""
                                        value={this.state.districtId ? this.state.districtId.toString() : undefined}
                                        onChange={this.handleDistrictChange}
                                        data-tst-id="district"
                                        disabled={this.state.districts !== undefined ? false : true}
                                    >
                                        <option value={""}/>
                                        {this.state.districts &&
                                        this.state.districts.map((district: GroupMaster) => {
                                            return (
                                                <option key={district.id} value={district.id}>
                                                    {district.name}
                                                </option>
                                            )
                                        })}
                                    </FormControl>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel>Filter by School</FormLabel>
                                    <FormControl
                                        as="select"
                                        placeholder=""
                                        value={this.state.schoolId ? this.state.schoolId.toString() : undefined}
                                        onChange={this.handleSchoolChange}
                                        data-tst-id="school"
                                        disabled={this.state.schools !== undefined ? false : true}
                                    >
                                        <option value={""}/>
                                        {this.state.schools &&
                                        this.state.schools.map((school: GroupMaster) => {
                                            return (
                                                <option key={school.id} value={school.id}>
                                                    {school.name}
                                                </option>
                                            )
                                        })}
                                    </FormControl>
                                </FormGroup>
                            </>
                        )}

                        {this.props.userType === "D" && this.state.schools && (
                            <FormGroup>
                                <FormLabel>Filter by School</FormLabel>
                                <FormControl
                                    as="select"
                                    placeholder=""
                                    value={this.state.schoolId ? this.state.schoolId.toString() : undefined}
                                    onChange={this.handleSchoolChange}
                                    data-tst-id="school"
                                >
                                    {this.state.schools &&
                                    this.state.schools.map((school: GroupMaster) => {
                                        return (
                                            <option key={school.id} value={school.id}>
                                                {school.name}
                                            </option>
                                        )
                                    })}
                                </FormControl>
                            </FormGroup>
                        )}

                        <br/>

                        <Button
                            variant="contained"
                            color="primary"
                            data-tst-id="apply-advance-report-filters"
                            style={{float: "right"}}
                            onClick={() => this.getUserData(0)}
                        >
                            Apply Filters
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderErrors = (error: boolean | string): React.ReactNode => {
        return (
            <div className="alerts">
                <Alert variant="danger" onClose={() => this.setState({error: false})}>
                    {error}
                </Alert>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.state.error && this.renderErrors(this.state.error)}
                <Container>
                    <Row>
                        <Col>
                            {this.renderSearch()}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
