import {action, observable, runInAction} from "mobx"
import {ProductPackage} from "../../../types/types"
import ApiService from "../../../services/ApiService"

export class ProductPackageStore {

    @observable
    productPackages: ProductPackage[] = []

    @observable
    productPackageToName: Map<string, string> = new Map<string, string>()

    @action
    initialize = async () => {
        if (this.productPackages.length === 0) {
            await this.getListOfProductPackages()
        }
    }

    @action
    getListOfProductPackages = async (): Promise<void> => {
        if (this.productPackages.length > 0) {
            return
        }
        try {
            const res = await ApiService.getListOfProductPackages()
            runInAction(() => {
                res.forEach((productPackage: ProductPackage) => {
                    this.productPackageToName.set(productPackage.code, productPackage.longName)
                })

                this.productPackageToName.set("hw", "Handwritten Writing Section")


                this.productPackages = res.sort((a, b) => a.code.localeCompare(b.code))
            })
        } catch (error) {
            console.error("Failed to fetch product packages", error)
        }
    }

    getProductPackageName = (product_package: string): string => {

        if (product_package === "hw") {
            return this.productPackageToName[product_package] || "0";
        }
        if (this.productPackages.length === 0) {
            this.initialize();
            return this.productPackages.find((pp) => pp.code === product_package)?.longName || "0";
        }

        return "0";

    }

}

const productPackageStore = new ProductPackageStore()
export default productPackageStore
