
import * as Sentry from "@sentry/browser"
import {createBrowserHistory} from "history"
import {observer} from "mobx-react"
import React from "react"
import {Router} from "react-router"
import {Dispatch} from "redux"
import {AuthStore} from "../../app/common/authentication/AuthStore"
import {LoadingSpinner} from "../../app/common/loaders/LoadingSpinner"
import {MessageView} from "../../app/common/messages/MessageView"
import {productStore} from "../../app/common/products/ProductStore"
import {Routes} from "../../app/routes/Routes"
import {Section} from "../../app/section/models/Section"
import {setCurrentPanelId, setPanelGraph, setTakeCode, setTestState, updateTake} from "../../redux/app/actions"
import {updateSection} from "../../redux/item/actions"
import ApiService from "../../services/ApiService"
import {ApiPanelGraph, AppConfig, Take, TestState} from "../../types/types"
import {deriveApiUrl} from "../../util/UrlUtil"
import NoConnectionModalContainer from "../NoConnectionModalContainer"
import TokenExpireModalContainer from "../TokenExpireModalContainer"
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles'
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles'
import {theme, themeV5} from "../../styles/MuiThemes"
import {generateClassName} from "../../styles/Mui4And5Utils"
import {LicenseInfo} from "@mui/x-date-pickers-pro";

declare const appConfigs: AppConfig

export interface TakeDispatches {
    setPanelGraphDispatch: (panelGraph: ApiPanelGraph) => void
    setCurrentPanelIdDispatch: (panelId: number) => void
    updateTakeDispatch: (testTaker: Take) => void
    setTakeCodeDispatch: (takeCode: string) => void
    updateSectionDispatch: (bin: Section) => void
    setTestStateDispatch: (testState: TestState) => void
}

export function addTakeDispatches(dispatches: {}, dispatch: Dispatch): any {
    return {
        ...dispatches,
        setPanelGraphDispatch: (panelGraph: ApiPanelGraph) => {
            dispatch(setPanelGraph(panelGraph))
        },
        setCurrentPanelIdDispatch: (currentPanelId: number) => {
            dispatch(setCurrentPanelId(currentPanelId))
        },
        updateTakeDispatch: (take: Take) => {
            dispatch(updateTake(take))
        },
        setTakeCodeDispatch: (takeCode: string) => {
            dispatch(setTakeCode(takeCode))
        },
        setTestStateDispatch: (testState: TestState) => {
            dispatch(setTestState(testState))
        },
        updateSectionDispatch: (section: Section) => {
            dispatch(updateSection(section))
        }
    }
}

export const avantHistory = createBrowserHistory()

@observer
export class App extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props)

        const apiUrl = deriveApiUrl()
        const isProduction = apiUrl.info.env.length === 0
        const sentryEnv = isProduction ? "production" : apiUrl.info.env.replace("-", "").toLowerCase()

        Sentry.init({
            dsn: "https://6b476e61cd984e87a25d875d81c8716a@sentry.io/1287543",
            environment: sentryEnv,
            debug: !isProduction,
            release: appConfigs.commitHash
        })
        AuthStore.TOKEN_REFRESH_SECONDS = appConfigs.REFRESH_TOKEN_COOLDOWN
        AuthStore.TOKEN_EXPIRES = appConfigs.AUTH_TOKEN_SECONDS_TILL_EXPIRE
        ApiService.API_URL = apiUrl.apiUrl
        ApiService.MEDIA_URL = new URL(appConfigs.MEDIA_BASE_URL)
        ApiService.CDN_BASE_URL = new URL(appConfigs.CDN_BASE_URL)
    }

    // I used this stack overflow to figure out how to leverage react to add and remove event listeners on the body
    // element: https://stackoverflow.com/questions/32485520/how-can-i-add-a-click-handler-to-body-from-within-a-react-component

    // I used this mdn article to figure out how to handle the prevention of the default behavior of various key combinations.
    // article: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent

    componentDidMount() {
        // This function is written to stop the default behavior of any keys or key combinations that open the dev tools.
        // It is added as an event listener to the body tag when the App component mounts.
        function blockDevTools(e: any) {
            // case if f12 was pressed
            if (e.keyCode === 123) {
                e.preventDefault()
            }
            // case if ctrl + shift + i was pressed
            if (e.keyCode === 73 && e.ctrlKey && e.shiftKey) {
                e.preventDefault()
            }
            // case if ctrl + shift + j was pressed
            if (e.keyCode === 74 && e.ctrlKey && e.shiftKey) {
                e.preventDefault()
            }
            // case if ctrl + shift + c was pressed
            if (e.keyCode === 67 && e.ctrlKey && e.shiftKey) {
                e.preventDefault()
            }
            // case if option (alt), command (meta), and i is pressed
            if (e.keyCode === 73 && e.altKey && e.metaKey) {
                e.preventDefault()
            }
            // case if option (alt), command (meta), and j is pressed
            if (e.keyCode === 74 && e.altKey && e.metaKey) {
                e.preventDefault()
            }
            // case if option (alt), command (meta), and c is pressed
            if (e.keyCode === 67 && e.altKey && e.metaKey) {
                e.preventDefault()
            }
            // case if shift, command (meta), and c is pressed
            if (e.keyCode === 67 && e.shiftKey && e.metaKey) {
                e.preventDefault()
            }
        }
        document.body.addEventListener("keydown", blockDevTools)
    }


    render() {
        LicenseInfo.setLicenseKey("628f3b113f032cf5b9d0c8dfd993d07fTz0xMDE3NzYsRT0xNzYzMTYxNjUxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=")
        return (
                <StylesProvider generateClassName={generateClassName}>
                    <ThemeProviderV4 theme={theme}>
                        <ThemeProviderV5 theme={themeV5}>
                            <Router history={avantHistory}>
                                <div className="notranslate">
                                    {productStore.driver && productStore.loginProduct && (
                                        <>
                                            <TokenExpireModalContainer/>
                                            <NoConnectionModalContainer/>
                                        </>
                                    )}
                                    <Routes/>
                                    <LoadingSpinner/>
                                    <MessageView/>
                                </div>
                            </Router>
                        </ThemeProviderV5>
                    </ThemeProviderV4>
                </StylesProvider>
        )
    }
}
